import React from 'react';
import './style.css'

const Footer = () => {
    return(
        <div className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="text">
                        <h2>Míša a Tom</h2>
                        <p>Děkujeme</p>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Footer;