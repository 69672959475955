import React from 'react'
import {Link} from 'react-router-dom'
import Sectiontitle from '../section-title'
import strory1 from '../../images/events/img-1.jpg'
import strory2 from '../../images/events/img-2.jpg'
import strory3 from '../../images/events/img-3.jpg'
import story4 from '../../images/events/img-4.jpg'

import './style.css'

const Location = () => {
    return (
        <div id="event" className="event-section section-padding">
            <Sectiontitle section={'Kdy a kde?'}/>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="tabs-site-button">
                            <div className="event-tabs">
                                <div className="col-md-12 col-12">
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={strory1} alt=""/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h3>Kocanda Kravsko</h3>
                                                    <p>Areál Kocandy se nachází v obci Kravsko nedaleko Znojma. Název
                                                        Kocanda pochází z německého “Gottseidank”, česky “Díkybohu”. Ve
                                                        středověku sloužila Kocanda jako zájezdní hostinec a vedla zde
                                                        hlavní cesta z Vídně do Prahy. V 19. století byl areál rozšířen
                                                        o průmyslové stavby místní keramičky.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text event-text-2">
                                                    <h3>Svatební obřad</h3>
                                                    <span>6.&nbsp;srpna 2021 v 14.00</span>
                                                    <span>Kocanda Kravsko</span>
                                                    <p>V den svatby se na místo se dostavte mezi 12 a 13 hodinou. Po
                                                        příjezdu na místo na vás bude čekat uvítací nápoj a menší
                                                        občerstvení před obřadem. Hosté, kteří budou mít zajištěno
                                                        ubytování, budou ubytováni. Ve 14:00 začne svatební obřad a poté
                                                        se přesuneme ke svatebním stolu, hodování a zábavě.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={strory2} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={strory3} alt=""/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h3>Svatební menu </h3>
                                                    <p>
                                                        Můžete se těšit na samé dobroty připravené šikovnými kuchaři
                                                        přímo z Kocandy. V nabídce nebude chybět hovězí tatarák, vepřová
                                                        panenka, domácí hamburgery, pečený losos a také sladké tiramisu,
                                                        moravské frgále nebo ovocné pannacotty.<br/>
                                                        Myslíme i na vegetariány, vegany a alergiky. Každý si vybere to
                                                        své.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text event-text-2">
                                                    <h3>Ubytování</h3>
                                                    <p>
                                                        Celý areál Kocanda Kravsko je rekonstruován s úctou k jeho
                                                        historii, jsou zde zachovány původní středověké i industriální
                                                        prvky, což se odráží i v zařízení jednotlivých pokojů. Každý
                                                        pokoj je unikátní. Jsou k dispozici vícelůžkové rodinné
                                                        apartmány, čtyřlůžkové, třílůžkové i dvoulůžkové pokoje.
                                                        Součástí ubytování je i snídaně další den ráno.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={story4} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Location;
