import React from 'react';
import {Link} from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import 'react-sticky-header/styles.css';
import StickyHeader from 'react-sticky-header';



import './style.css'

const Header = () => {

    return(
        <StickyHeader
         header={
          <div className="Header_root">
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="logo">
                                <h2><Link to='/'>Míša & Tom</Link></h2>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="header-menu d-lg-block d-none">
                                <ul className="mobile-menu d-flex">
                                    <li><Link to='/'>Domů</Link></li>
                                    <li><AnchorLink href='#couple'>O nás</AnchorLink></li>
                                    <li><AnchorLink href='#story'>Příběh</AnchorLink></li>
                                    <li><AnchorLink href='#people'>Lidé</AnchorLink></li>
                                    <li><AnchorLink href='#event'>Kdy a kde</AnchorLink></li>
                                    <li><AnchorLink href='#gallery'>Fotky</AnchorLink></li>
                                    <li><AnchorLink href='#rsvp'>Pozvánka</AnchorLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
          </div>
        }
      >
      </StickyHeader>

    )
}

export default Header;
