import React from 'react';
import gta3 from '../../images/gta/img-3.jpg'
import Sectiontitle from '../section-title'
import './style.css'

import {compose, withProps} from "recompose"
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps"

const MyMapComponent = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyA6JiW6ZN_ebp-kbyz395WdXIfahVs6TPs&libraries=geometry,drawing,places",
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{height: `400px`}}/>,
        mapElement: <div style={{height: `100%`}}/>,
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <GoogleMap
        defaultZoom={7.1}
        defaultCenter={{lat: 49.8037633, lng: 15.4749126}}
    >
        {props.isMarkerShown && <Marker position={{lat: 48.9281292, lng: 15.9863550}} onClick={props.onMarkerClick}/>}
    </GoogleMap>
)

const Getting = () => {
    return (
        <>
            <div className="gta-area section-padding">
                <Sectiontitle section={'Jak se k nám dostanete?'}/>
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                            <div className="row">
                                <div className="heading col-md-12 col-sm-12">
                                    <div className="gta-content">
                                        <p>Po příjezdu do obce Kravsko pokračujete dále po hlavní cestě, přejedete celou
                                            obec a vyjedete na kopec, zde se nachází Kocanda. Je zde k dispozici
                                            prostorné
                                            parkoviště.<br/>
                                            Na začátku obce se v polích nachází další objekt: Stodola Suška, zde se také
                                            pořádají svatby, proto je možné, že poblíž cesty budou cedule s nápisem
                                            “svatba”, sem nejezděte a pokračujte dále přes obec dle výše popsané
                                            cesty.<br/>
                                            Kdyby se někdo ztratil a nemohl najít cestu, nebojte se nám zavolat a my Vás
                                            nasměrujeme. :)<br/>
                                            Můžete volat na telefonní čísla:{' '}
                                            <a href="tel:+420775376444">+420 775 376 444</a>,{' '}
                                            <a href="tel:+420775526640">+420 775 526 640</a>
                                        </p>
                                    </div>
                                    <div className="gta-img">
                                        <MyMapComponent isMarkerShown/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding">
                <Sectiontitle section={'Co si obléct?'}/>
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                            <div className="row">
                                <div className="heading heading-2 col-md-12 col-sm-6">
                                    <div className="gta-content">
                                        <p>Co na sebe a v jakém stylu bude svatba?</p>
                                            <p>Oblečte se tak,abyste se cítili dobře a pohodlně. Barvou naší svatby
                                            bude <b>fialová</b>.</p>
                                    </div>
                                    <div className="gta-img">
                                        <img src={gta3} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Getting;
