import React from 'react'
import {Link} from 'react-router-dom'
import Sectiontitle from '../section-title'
import story1 from '../../images/story/img-1.jpg'
import strory2 from '../../images/story/img-2.jpg'
import strory3 from '../../images/story/img-3.jpg'
import strory4 from '../../images/story/img-4.jpg'
import story5 from '../../images/story/img-5.jpg'
import story6 from '../../images/story/img-6.jpg'
import './style.css'


const Story = () => {
    return (
        <div id="story" className="story-area section-padding">
            <Sectiontitle section={'Náš příběh'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={story1} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <h3>První rande</h3>
                                <span className="date">1. květen 2015</span>
                                <p>Na prvního máje jsme se políbili pod rozkvetlou třešní a věděli jsme, že už nikdy
                                    nechceme být jeden bez druhého.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text right-align-text">
                                <h3>První společná dovolená</h3>
                                <span className="date">duben 2016</span>
                                <p>Oba milujeme cestování, ráno se probudíme, nasedneme do auta a jedeme do neznáma.
                                    Procestoval jsme spoustu zemi a zajímavých míst a doufáme, že ty nejkrásnější cesty
                                    nás ještě čekají.</p>
                                <div className="story-button">
                                    <a className="theme-btn"
                                       href='https://www.icloud.com/sharedalbum/cs-cz/#B1AGY8gBYGjG2cg'
                                       target='gallery'>Naše fotky z cest</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={strory2} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={strory3} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <h3>Vlastní bydlení</h3>
                                <span className="date">září 2017</span>
                                <p>I když toužíme procestovat celý svět, tak náš domov, kam se budeme rádi vracet, bude
                                    vždy Brno, proto jsme v&nbsp;létě roku 2017 začali hledat v&nbsp;Brně společné
                                    bydlení a za pár týdnů jsme se už stěhovali. </p>
                                <div className="story-button">
                                    <a className="theme-btn" href='https://www.icloud.com/sharedalbum/#B1A5nhQSTD4oUQ'
                                       target='gallery'>Fotky z vlastního bydlení</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text right-align-text">
                                <h3>Zásnuby</h3>
                                <span className="date">31. červenec 2020</span>
                                <p>V létě roku 2020 jsme se vydali na výlet do Vídně. V mrakodrapu DC Tower Tomáš
                                    poklekl a Míša byla v šoku, že skoro zapomněla říct “ano”. Vše ale dobře dopadlo a
                                    svatba bude dalším krásným milníkem našeho životního dobrodružství.</p>
                                <div className="story-button">
                                    <a className="theme-btn" href='https://www.icloud.com/sharedalbum/#B1A5Uzl7VdSZpv'
                                       target='gallery'>Zásnubní fotky</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={strory4} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={story5} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <h3>Operace</h3>
                                <span className="date">20. leden 2021</span>
                                <p>Po dlouhém boji se skoliózou Míša podstoupila náročnou operaci páteře. Operace byla
                                    úspěšná, ale rekonvalescence je velmi těžká a bude trvat dlouho než se vše vrátí k
                                    normálu, ale s pomocí Toma a všech milovaných lidí je vše jednodušší.</p>
                                <p>Chceme Vás poprosit o shovívavost ke stavu nevěsty, chceme se vyvarovat náročnějších
                                    fyzických aktivit, aby Míša celý den zvládla bez větších obtíží. </p>
                                <div className="story-button">
                                    <a className="theme-btn" href='https://www.icloud.com/sharedalbum/#B1AGI9HKKJKPHLW'
                                       target='gallery'>Fotky</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text right-align-text">
                                <h3>Svatba</h3>
                                <span className="date">6. srpen 2021</span>
                                <p>Tuhle kapitolu napíšeme již brzy společně s vámi.</p>
                                <div className="story-button">
                                    <a className="theme-btn" href='https://www.icloud.com/sharedalbum/#B1AGrq0zwJIS7FQ'
                                       target='gallery'>Svatební fotky</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={story6} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Story;
