import React from 'react'
import Sectiontitle from '../section-title'
import couple1 from '../../images/couple/img-1.jpg'
import couple2 from '../../images/couple/img-2.jpg'
import './style.css'

const Couple = () => {
    return(
        <div id="couple" className="about-wrap">
            <div className="couple-area section-padding">
                <Sectiontitle section={'Kdo se bere?'}/>
                <div className="container">
                    <div className="couple-wrap">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 couple-single">
                                <div className="couple-wrap couple-wrap-2">
                                    <div className="couple-img">
                                        <img src={couple2} alt="thumb"/>
                                    </div>
                                    <div className="couple-text">
                                        <div className="couple-content">
                                            <h3>Michaela Novotná</h3>
                                            <p>Vášnivá čtenářka neustále kupující tuny knih, které nemá nikdy šanci přečíst, milovnice italského jídla a nadšená pěstitelka pokojových rostlin, které ne vždy přežijí. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 couple-single">
                                <div className="couple-wrap couple-wrap-3">
                                    <div className="couple-img couple-img-2">
                                        <img src={couple1} alt="thumb"/>
                                    </div>
                                    <div className="couple-text">
                                        <div className="couple-content">
                                            <h3>Tomáš Šůstek</h3>
                                            <p>Počítačový maniak se smyslem pro dobré jídlo, auta a všechno co trochu zavání nějakou technologií.</p><p>Nejlíp mu je za volantem, s Míšou na sedadle spolujezdkyně a na cestě, která nemá předem daný cíl. Nejspíš, ale její součástí bude zastávka v Itálii nebo alespoň v Rakousku.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Couple;
